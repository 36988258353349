<template>
       <router-view></router-view>
</template>
<script>
export default {
    name: "kinnections-public-containter",
    setup() {

    }
}  	
</script>
